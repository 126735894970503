.game-container {
	position: absolute;
	top: 100px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	width: 800px;
	height: 600px;
	margin: auto;
	background-color: #595959;
	background-image: url('../../assets/ground.png');
	background-size: auto;
	background-position: center;
	box-shadow: inset 0px 0px 14px 0px rgba(#000, 1);
}
