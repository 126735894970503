.selection {
	position: relative;
	flex: 1 0 auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 150px;
	border-width: 4px;
	border-style: solid;
	border-image: linear-gradient(to right, #b3b3b3, #333) 1;
	background-color: #010101;

	.selection-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		.selection-image {
			width: 90px;
		}

		p {
			margin: 0;
			color: #00f800;
		}
	}

	.selection-details {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: 1rem 0;
		font-size: 10px;
		color: rgb(193, 230, 193);

		h5 {
			margin-bottom: auto;
			font-size: 20px;
		}
	}

	.armored-icon {
		position: relative;
		z-index: 2;
		width: 42px;
		margin-bottom: 5px;
	}

	.tooltip {
		position: absolute;
		top: 15px;
		right: -42px;
		z-index: 2;
		padding: 5px;
		border: 1px solid rgba(129, 228, 129, 0.75);
		outline: 1px solid gray;
		border-radius: 2px;
		font-size: 12.5px;
		background-color: rgba(rgb(12, 51, 12), 0.75);

		.tooltip-title {
			margin-bottom: 10px;
			font-weight: bold;
		}

		p {
			margin: 0;
		}
	}
}
