.mini-map {
  position: relative;
  width: 170px;
  height: 170px;
  border-width: 4px;
  border-top-width: 12px;
  border-style: solid;
  border-image: linear-gradient(to right, #333, #b3b3b3) 1;
  background-color: #000;

  .timer {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 2px 4px;
    outline: 2px solid rgb(147, 147, 147);
    border: 1px solid rgb(190, 235, 200);
    border-radius: 5px;
    color: rgb(190, 235, 200);
    background-color: #000;
  }

  .visible-area {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    margin: auto;
    border-radius: 50%;
    background-color: rgb(97, 97, 97);
  }

  .view-box {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    width: 40px;
    height: 30px;
    margin: auto;
    border: 1px solid #fff;
    transform: perspective(7px) rotateX(2deg);
  }

  .player-base {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    width: 12px;
    height: 12px;
    margin: auto;
    background-color: rgb(26, 180, 47);
  }
}
