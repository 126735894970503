.status {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: flex;

  .status-section {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    i {
      width: 20px;
      margin: auto 0.5rem auto 0;

      img {
        width: 100%;
      }
    }

    span {
      text-shadow: 2px 2px #000;
    }
  }
}
