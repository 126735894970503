.mineral-miner-project {
	position: relative;
	height: 700px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 18px;
	line-height: 1;
	color: #ddd;
	$link-color: rgb(49, 182, 100);

	a,
	p,
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-weight: 500;
	}

	h1 {
		margin-bottom: 20px;
		font-size: 40px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 20px;
	}

	a {
		text-decoration: none;
		color: $link-color;

		&:hover {
			color: lighten($link-color, 5%);
			transition: 0.3s ease;
		}
	}

	b {
		font-weight: bold;
	}

	img {
		display: block;
		width: auto;
	}

	div > img {
		width: 100%;
	}

	li {
		margin-bottom: 1rem;
	}

	button {
		&:hover {
			cursor: pointer;
		}
	}

	code {
		padding: 0 3px;
		border-radius: 3px;
		font-family: courier, monospace;
		background-color: #555;
	}

	.app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	.page {
		position: relative;
		flex: 1 1;
		width: 100%;
		max-width: 1024px;
		height: auto;
		margin: 0 auto;
		background-color: #000;
	}

	.container {
		position: relative;
		width: 100%;
		max-width: 1024px;
		margin: 0 auto;
		padding: 1.5rem;
	}
}
