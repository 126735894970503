.portrait {
  width: 98px;
  height: 150px;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to right, #333, #b3b3b3) 1;
  background-color: #000;

  img {
    height: 100%;
  }
}
