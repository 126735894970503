.exercise-logs-project {
  h4 {
    color: #fff;
  }
  .logo-container {
    display: flex;
    align-items: center;
    h1 {
      position: relative;
      z-index: 7;
    }
    .exercise-logo {
      width: 2.75rem;
      margin-left: 0.5rem;
      // border-radius: 50%;
      // overflow: hidden;
      animation: run-in 1s ease-out forwards;
      animation-delay: 1s;
      position: relative;
      left: 100%;
      top: 0.2rem;

      @keyframes run-in {
        from {
          left: -100%;
        }

        to {
          left: 0%;
        }
      }
    }
  }

  .exercise-logs-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    margin: 2rem 0;
    padding: 0.5rem;
    background-color: #eee;
    background-image: url(../ExerciseLogs/assets/logs-bg.jpg);
    background-size: cover;

    > div {
      padding: 0.4rem;
    }
  }
  .exercise-logs {
    display: flex;
    flex-direction: column-reverse;
    // max-width: 500px;
    margin-top: 3rem;
    width: 100%;
    max-width: 30rem;

    h4,
    h5 {
      margin-bottom: 1rem;
    }
    .exercise-log {
      display: flex;
      position: relative;
      border: 1px solid black;
      max-width: 100%;
      margin-bottom: 1rem;
      padding: 0.6rem;
      border-radius: 1px;
      background-color: rgba(255, 255, 255, 0.9);
      > div {
        // normal view or edit view
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        header {
          display: flex;
          width: 100%;

          h4 {
            width: 100%;
            margin-right: auto;
          }

          .controls {
            margin-left: 0.4rem;
            span {
              &:hover {
                cursor: pointer;
                color: coral;
              }

              &:first-of-type {
                margin-right: 0.3rem;
              }
            }
          }
        }

        .log-row {
          display: flex;
          width: 100%;
          border: 1px solid black;
          border-bottom: 0;

          &:nth-of-type(odd) {
            background-color: rgb(173, 192, 211);
          }

          &:nth-of-type(even) {
            background-color: rgb(112, 139, 165);
          }

          &:last-of-type {
            border-bottom: 1px solid black;
          }

          label {
            text-align: right;
            flex: 0 0 6.1rem;
            padding: 0.4rem;
            font-size: 0.9rem;
            font-weight: bold;
            border-right: 1px solid black;
          }

          p {
            width: auto;
            margin-bottom: 0px;
            padding: 0.4rem;
            overflow: hidden;

            overflow-wrap: break-word;
          }
        }
      }

      .edit-mode {
        .edit-exercise-log {
          width: 100%;

          input,
          textarea {
            width: 100%;
            font-size: 1rem;
            padding: 0.2rem;
          }

          textarea {
            resize: none;
            height: 100%;
            min-height: 170px;
            max-height: 300px;
          }
        }
      }
    }
  }

  .create-log-container {
    flex: 0 1 30rem;
    margin-top: 1rem;

    .create-exercise-log {
      display: flex;
      flex-direction: column;
      border: 1px solid black;
      margin-top: 1rem;
      padding: 0.8rem;
      border-radius: 1px;
      background-color: rgba(173, 192, 211, 0.9);

      > div {
        display: flex;
        label {
          flex: 0 0 6rem;
          padding: 0.1rem 0.5rem 0 0;
          font-size: 0.9rem;
          text-align: right;
          font-weight: bold;
          overflow-wrap: normal;
        }

        input {
          flex: 1 auto;
        }

        textarea {
          resize: none;
        }
      }

      button {
        margin-top: 0.5rem;
        align-self: flex-end;

        &:hover {
          background-color: #fff;
        }
      }

      p {
        font-size: 0.6rem;
      }
    }
  }
}
