.todos-container {
  min-height: 360px;
  max-height: 572px;
  margin: 1rem 0;
  border: 1px solid black;
  padding: 2rem 0.75rem;

  background-color: #e7ecf1;

  .todos {
    display: flex;
    flex-wrap: wrap;

    .todo {
      display: flex;
      width: 100%;
      max-width: 48%;
      align-items: center;
      padding: 0.75rem 0.75rem;
      margin: 0.2rem;
      border: 1px solid black;
      position: relative;
      word-break: break-word;

      @media screen and (max-width: 600px) {
        // max-width: 100%;
      }

      .checkbox {
        flex: 0 0 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem;
        border: 1px solid black;
        background-color: #fff;

        &:hover {
          cursor: pointer;
          background-color: #eee;
        }

        span {
        }
      }

      .todo-title {
        p {
          font-size: 1rem;
          margin-bottom: 0;
          padding-right: 0.5rem;
          filter: invert(1);
          word-break: break-word;

          span {
            word-break: break-word;
          }
        }
      }

      .delete {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        font-size: 0.75rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.control-container {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .create-todo-form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.75rem;
    border: 1px solid black;
    position: relative;
    margin-right: 1rem;
    background-color: #e7ecf1;

    @media screen and (max-width: 768px) {
      order: 2;
      margin-top: 1rem;
      margin-right: 0;
    }

    .todo-inputs {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin: 0.75rem 0;

      input[type="color"] {
        width: 3rem;
        height: 2rem;
        margin-left: 0.2rem;
        padding: 0;
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }

    button {
      padding: 0.4rem 1rem;
      font-weight: bold;
      border: 1px solid #444;

      &:hover {
        background-color: #ddd;
        cursor: pointer;
      }
    }
  }

  .message-box {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: 1px solid black;
    background-color: #e7ecf1;

    p {
      margin-top: 0.75rem;
      font-weight: bold;
    }
  }
}
