.mineral-field {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translateX(-50%);
}

.mineral-patch {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 32px;
  height: 350px;
  margin: 0 auto;

  &:nth-of-type(1) {
    transform: rotate(-45deg);
  }
  &:nth-of-type(2) {
    transform: rotate(-30deg);
  }
  &:nth-of-type(3) {
    transform: rotate(-15deg);
  }
  &:nth-of-type(4) {
    transform: rotate(0deg);
  }
  &:nth-of-type(5) {
    transform: rotate(15deg);
  }
  &:nth-of-type(6) {
    transform: rotate(30deg);
  }
  &:nth-of-type(7) {
    transform: rotate(45deg);
  }
  &:nth-of-type(8) {
    transform: rotate(60deg);
  }
}
